/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import * as React from "react"
import "/src/styles.scss"
import "@fontsource/poppins"
import "@fontsource/poppins/200.css"
import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"

import {Introduction} from "../components/introduction"
import {TechnologyBlock} from "../components/technology"
import {HighlightBlock} from "../components/highlight"
import {Layout} from "../components/layout"
import {Countdown} from "../components/countdown"
import {CandidateProfile} from "../components/candidate"
import {Testimonial} from "../components/testimonial"
import {Process} from "../components/process"
import {Video} from "../components/video"
import {Text} from "../components/text"

import {useStaticQuery, graphql} from "gatsby"
import {ContentBlock, IntroductionBlock} from "../types/content-blocks"
import {DatoHomepageResponse, DatoContentBlock} from "../types/homepage"

const IndexPage = () => {
  const {datoCmsHomepage: homepage} =
    useStaticQuery<DatoHomepageResponse>(graphql`
      {
        datoCmsHomepage {
          vacancyActive
          content {
            ... on DatoCmsCallToAction {
              model {
                apiKey
              }
              id
              title
              url
            }
            ... on DatoCmsCandidateProfile {
              model {
                apiKey
              }
              id
              title
              prosAndCons {
                id
                title
                bullets {
                  text
                }
              }
              callToActionText
              callToActionLink
            }
            ... on DatoCmsCountdown {
              model {
                apiKey
              }
              id
              title
              date
            }
            ... on DatoCmsHighlight {
              model {
                apiKey
              }
              id
              title
              content
              items {
                id
                title
                text
                icon
              }
              bulletPoints {
                text
              }
              finePrint
            }
            ... on DatoCmsIntroduction {
              model {
                apiKey
              }
              id
              title
              subtitle
              introductionHeader
              introductionText
              image {
                url
              }
              url
            }
            ... on DatoCmsProcess {
              model {
                apiKey
              }
              id
              title
              steps {
                id
                title
                subtitle
              }
              url
              callToAction
            }
            ... on DatoCmsTechnology {
              model {
                apiKey
              }
              id
              title
              content
              items {
                id
                name
                logo {
                  url
                }
              }
            }
            ... on DatoCmsTestimonial {
              model {
                apiKey
              }
              id
              title
              items {
                id
                name
                year
                quote
                color {
                  hex
                }
                subtleColor {
                  hex
                }
                fontColor {
                  hex
                }
                photo {
                  url
                }
              }
            }
            ... on DatoCmsText {
              model {
                apiKey
              }
              id
              title
              content
            }
            ... on DatoCmsVideo {
              model {
                apiKey
              }
              id
              video {
                url
              }
              playButtonText
              preview {
                url
              }
            }
          }
        }
      }
    `)

  const content = datoContentToType(homepage.content)

  return (
    <Layout
      vacancyActive={homepage.vacancyActive}
      url={
        (
          content.find((block: ContentBlock) => {
            return block.type === "introduction"
          }) as IntroductionBlock
        ).url
      }
    >
      {content.map(block => {
        switch (block.type) {
          case "introduction":
            return (
              <Introduction
                key={block.id}
                {...block}
                vacancyActive={homepage.vacancyActive}
              />
            )
          case "technology":
            return <TechnologyBlock key={block.id} {...block} />
          case "highlight":
            return <HighlightBlock key={block.id} {...block} />
          case "candidate_profile":
            return <CandidateProfile key={block.id} {...block} />
          case "countdown":
            return <Countdown key={block.id} {...block} />
          case "testimonial":
            return <Testimonial key={block.id} {...block} />
          case "process":
            return (
              <Process
                key={block.id}
                {...block}
                vacancyActive={homepage.vacancyActive}
              />
            )
          case "text":
            return <Text key={block.id} {...block} />
          case "video":
            return <Video key={block.id} {...block} />
          default:
            return null
        }
      })}
    </Layout>
  )
}

export default IndexPage

const datoContentToType = (content: DatoContentBlock[]): ContentBlock[] => {
  return content.map(
    block =>
      ({
        ...block,
        type: block.model.apiKey,
      } as ContentBlock),
  )
}
