/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import {TextBlock as IText, Item} from "../types/content-blocks"
import {theme} from "../components/styles/Theme"

export const Text = (content: IText) => {
  return (
    <div css={TextStyles}>
      <h1>{content.title}</h1>
      <div dangerouslySetInnerHTML={{__html: content.content}} />
    </div>
  )
}

const TextStyles = css`
  margin: auto;
  margin-top: 12rem;
  text-align: center;
  width: 70%;

  h1 {
    margin-bottom: 4rem;
  }

  div {
    font-size: 2rem;
    font-weight: 200;

    @media screen and (${theme.breakpoints.desktopDown}) {
      margin: auto;
      font-size: 1.6rem;
    }
  }
`
