/** @jsx jsx */
import {useEffect, useState} from "react"
import {css, jsx} from "@emotion/react"
import {CountdownBlock} from "../types/content-blocks"
import {theme} from "../components/styles/Theme"

export const Countdown = (content: CountdownBlock) => {
  const getSecondsRemaining = () =>
    Math.abs(Date.parse(content.date) - new Date().getTime()) / 1000

  const [secondsLeft, setSecondsLeft] = useState(getSecondsRemaining())

  useEffect(() => {
    const secondsTimer = setInterval(() => {
      setSecondsLeft(getSecondsRemaining())
    }, 1000)
    return () => clearInterval(secondsTimer)
  }, [setSecondsLeft])

  const days = Math.floor(secondsLeft / 60 / 60 / 24)
  const hours = Math.floor(secondsLeft / 60 / 60) - days * 24
  const minutes = Math.floor(secondsLeft / 60) - hours * 60 - days * 24 * 60
  const seconds =
    Math.floor(secondsLeft) -
    minutes * 60 -
    hours * 60 * 60 -
    days * 24 * 60 * 60

  if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
    return (
      <div css={CountdownStyles}>
        <div>{content.title}</div>
        <p className="counter">The Voormedia Academy has started! 🎉</p>
      </div>
    )
  }

  return (
    <div css={CountdownStyles}>
      <div>{content.title}</div>
      <p className="counter">
        {days > 0 && `${days} day`}
        {days == 1 ? "" : "s"},&nbsp;
        {hours} hour{hours == 1 ? "" : "s"},&nbsp;
        {minutes} minute{minutes == 1 ? "" : "s"} and&nbsp;
        {seconds} second{seconds == 1 ? "" : "s"}
      </p>
    </div>
  )
}

const CountdownStyles = css`
  margin: 0 auto;
  margin-top: 12rem;
  max-width: 80rem;
  height: 12rem;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #212121;
  font-size: 1.8rem;
  color: #ffffff;

  @media screen and (${theme.breakpoints.desktopDown}) {
    margin-top: 6rem;
  }

  @media screen and (${theme.breakpoints.mediumDown}) {
    margin-top: 4rem;
  }

  p.counter {
    font-size: 1.8rem;

    @media screen and (${theme.breakpoints.smallDown}) {
      font-size: 1.6rem;
    }
  }

  > div {
    font-size: 1.6rem;
    color: #909090;
    text-transform: uppercase;

    @media screen and (${theme.breakpoints.smallDown}) {
      font-size: 1.4rem;
    }
  }
`
