/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import * as React from "react"
import {theme} from "../../components/styles/Theme"

interface Props {
  color: "orange" | "black" | "gradient"
  width: number
  height: number
  fontWeight: "bold" | "normal"
  text: string
  className?: string
  url: string
}

export const Button = (props: Props) => {
  return (
    <button
      css={ButtonStyles(props)}
      onClick={() => {
        window.location.href = props.url
      }}
      className={props.className}
    >
      <p>{props.text}</p>
    </button>
  )
}

const ButtonStyles = (props: Props) => css`
  width: ${`${props.width}rem`};
  height: ${`${props.height}rem`};
  border-radius: ${props.color == "orange" ? "2.5rem" : "1.5rem"};
  font-size: 1.7rem;
  color: white;
  background-color: ${props.color == "orange"
    ? theme.colors.buttoncolors.orange
    : theme.colors.buttoncolors.black};
  background-image: ${props.color == "gradient"
    ? theme.colors.gradients.button
    : ""};
  outline: none;
  border: none;
  cursor: pointer;

  @media screen and (${theme.breakpoints.mobileDown}) {
    width: 100%;
  }

  &:hover {
    filter: brightness(0.9);
  }

  p {
    font-size: 1.6rem;
    font-weight: ${props.fontWeight == "bold" ? 600 : 200};
  }
`
