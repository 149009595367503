/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import * as React from "react"
import {Helmet} from "react-helmet"
import {HelmetDatoCms} from "gatsby-source-datocms"

import {PropsWithChildren, ReactNode} from "react"
import Logo from "/src/images/logo.svg"
import BlobOrange from "/src/images/blob-orange.svg"
import BlobBlue from "/src/images/blob-blue.svg"
import {Button} from "../components/shared/button"
import {theme} from "../components/styles/Theme"
import {useStaticQuery, graphql} from "gatsby"

interface Props {
  vacancyActive: boolean
  children: PropsWithChildren<ReactNode>
  url: string
}

export const Layout = ({vacancyActive, children, url}: Props) => {
  const {
    datoCmsSeo: {richData, seoMetaTags},
  } = useStaticQuery(graphql`
    {
      datoCmsSeo {
        richData
        seoMetaTags {
          tags
        }
      }
    }
  `)

  const footerColumns = [
    {
      title: "Voormedia",
      items: [
        {text: "Homepage", url: "https://voormedia.com/"},
        {text: "Contact", url: "https://voormedia.com/contact/"},
      ],
    },
    {
      title: "Call",
      items: [{text: "+31 20 7155310", url: "tel:+31 20 7155310"}],
    },
    {
      title: "Write",
      items: [{text: "info@voormedia.com", url: "mailto: info@voormedia.com"}],
    },
    {
      title: "Visit",
      items: [
        {
          text: "Meeuwenlaan 100<br/>1021 JL Amsterdam<br/>The Netherlands",
          url: "",
        },
      ],
    },
  ]

  return (
    <div style={{overflow: "hidden"}}>
      <HelmetDatoCms seo={seoMetaTags} />
      <Helmet>
        <script type="application/ld+json">{richData}</script>
        <script type="text/javascript">
          {`
              window.Trengo = window.Trengo || {};
              window.Trengo.key = 'JWwsN4Md4AQsdqxdfj48';
              (function(d, script, t) {
                script = d.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.src = 'https://cdn.widget.trengo.eu/embed.js';
                d.getElementsByTagName('head')[0].appendChild(script);
              }(document));`}
        </script>
      </Helmet>

      <div className="page-width" css={PageWidth}>
        <img src={BlobOrange} className="orange blob" alt="blob" />
        <img src={BlobBlue} className="blue blob" alt="blob" />
        <div className="top">
          <img src={Logo} className="logo" alt="Voormedia Academy logo" />
          <Button
            text={vacancyActive ? "Apply now" : "Notify me"}
            width={20}
            height={4.6}
            fontWeight="normal"
            color="black"
            className="top-button"
            url="https://jobs.voormedia.com/traineeship/en/apply?&step=1"
          />
        </div>
        {children}
      </div>
      <div css={FooterStyles}>
        {footerColumns.map((column, index) => {
          return (
            <div className="column" key={index}>
              <p className="title">{column.title}</p>
              {column.items.map((item, index) => {
                return (
                  <div key={index}>
                    <a
                      className={item.url == "" ? "no-link" : "link"}
                      title={item.text}
                      dangerouslySetInnerHTML={{__html: item.text}}
                      href={item.url == "" ? "#" : item.url}
                    ></a>
                  </div>
                )
              })}
            </div>
          )
        })}
        <p className="copyright">© {new Date().getUTCFullYear()} Voormedia</p>
      </div>
    </div>
  )
}

const PageWidth = css`
  position: relative;
  z-index: 100;
  max-width: 124rem;
  padding: 4rem 6rem;
  margin: auto;

  .blob {
    position: absolute;
    opacity: 0.08;
    height: 120rem;

    &.blue {
      top: -60rem;
      right: -60rem;
      z-index: -1;
    }

    &.orange {
      top: -41rem;
      left: -90rem;
      z-index: -1;
    }
  }

  @media screen and (${theme.breakpoints.smallDown}) {
    padding: 2rem 3rem;

    button.top-button {
      display: none;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;

    @media screen and (${theme.breakpoints.smallDown}) {
      justify-content: center;
    }

    img.logo {
      max-width: 39rem;
      margin-bottom: 8rem;

      @media screen and (${theme.breakpoints.mediumDown}) {
        max-width: 26rem;
        margin-bottom: 6rem;
      }

      @media screen and (${theme.breakpoints.mobileDown}) {
        width: 70%;
      }
    }
  }
`

const FooterStyles = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  height: 53rem;
  padding: 10rem 11rem;
  background-color: #212121;
  color: white;

  @media screen and (${theme.breakpoints.desktopDown}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (${theme.breakpoints.mediumDown}) {
    height: fit-content;
    padding: 2rem 6rem 8rem;
  }

  @media screen and (${theme.breakpoints.smallDown}) {
    grid-template-columns: 1fr;
  }

  p.copyright {
    margin-top: 6rem;
    font-size: 2rem;

    @media screen and (${theme.breakpoints.mediumDown}) {
      font-size: 1.6rem;
    }
  }

  .column {
    p.title {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 2rem;
      @media screen and (${theme.breakpoints.mediumDown}) {
        margin-top: 4rem;
      }
    }

    a {
      cursor: pointer;
      color: white;
      text-decoration: none;
      font-size: 2rem;
      line-height: 3.5rem;
      font-weight: 200;

      &.no-link {
        cursor: auto;
      }

      @media screen and (${theme.breakpoints.mediumDown}) {
        font-size: 1.6rem;
      }
    }
  }
`
