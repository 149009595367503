/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import * as React from "react"
import {theme} from "../components/styles/Theme"
import {Button} from "../components/shared/button"
import {IntroductionBlock} from "../types/content-blocks"

export const Introduction = (content: IntroductionBlock) => {
  return (
    <div css={IntroductionStyles}>
      <div className="text-block">
        <div>
          <h2>{content.subtitle}</h2>
          <h1>{content.title}</h1>
          <hr />
          <h3>{content.introductionHeader}</h3>
          <p className="intro-text">{content.introductionText}</p>
        </div>
        <Button
          text={content.vacancyActive ? "Apply now" : "Get notified"}
          width={29}
          height={8.3}
          fontWeight="bold"
          color="gradient"
          url={content.url}
        />
      </div>
      <img src={content.image.url} className="team" alt="team" />
    </div>
  )
}

const IntroductionStyles = css`
  display: flex;

  @media screen and (${theme.breakpoints.desktopDown}) {
    text-align: center;
    justify-content: center;
  }

  img {
    max-height: 47rem;
    width: auto;

    @media screen and (${theme.breakpoints.desktopDown}) {
      display: none;
    }
  }

  .text-block {
    margin-right: 10rem;
    align-content: space-between;

    @media screen and (${theme.breakpoints.largeDesktopDown}) {
      margin-right: 4rem;
    }

    @media screen and (${theme.breakpoints.desktopDown}) {
      margin-right: 0rem;
    }

    h1 {
      font-weight: 600;
      font-size: 4.8rem;

      @media screen and (${theme.breakpoints.smallDown}) {
        font-size: 4rem;
      }
    }

    h2 {
      font-weight: 200;
      color: ${theme.colors.fontcolors.orange};
    }

    h3 {
      font-weight: 500;
      margin-bottom: 2rem;
    }

    hr {
      width: 4rem;
      height: 0.2rem;
      margin: 1rem 0rem;
      margin-right: auto;
      outline: none;
      border: none;
      background-color: ${theme.colors.fontcolors.orange};

      @media screen and (${theme.breakpoints.desktopDown}) {
        margin: 2rem auto;
      }
    }

    p.intro-text {
      width: 80%;
      margin-bottom: 6rem;
      font-size: 2rem;

      @media screen and (${theme.breakpoints.desktopDown}) {
        margin: auto;
        margin-bottom: 6rem;
      }

      @media screen and (${theme.breakpoints.smallDown}) {
        width: 100%;
        margin-bottom: 3rem;
        font-size: 1.4rem;
      }
    }
  }
`
