/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import * as React from "react"
import {theme} from "../components/styles/Theme"
import {HighlightBlock as IHighlightBlock} from "../types/content-blocks"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import {
  faCatSpace,
  faIslandTropical,
  faGraduationCap,
  faRocket,
  faQuestion,
} from "@fortawesome/pro-light-svg-icons"

export const HighlightBlock = (content: IHighlightBlock) => {
  const getIcon = (icon: string) => {
    switch (icon) {
      case "cat-space":
        return faCatSpace
      case "graduation-cap":
        return faGraduationCap
      case "island-tropical":
        return faIslandTropical
      case "rocket":
        return faRocket
      default:
        return faQuestion
    }
  }

  return (
    <div css={HighlightStyles}>
      <div className="left">
        <div>
          <h1>{content.title}</h1>
          <h4>{content.content}</h4>
          {content.bulletPoints.map((bullet, index) => {
            return (
              <div key={index} className="bullet">
                <FontAwesomeIcon icon={faCheck} />
                <p className="large">{bullet.text}</p>
              </div>
            )
          })}
        </div>
        <p className="text">{content.finePrint}</p>
      </div>
      <div className="right">
        {content.items.map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="icon-wrapper">
                <FontAwesomeIcon
                  icon={getIcon(item.icon)}
                  className={`icon ${item.icon}`}
                />
              </div>
              <div className="block">
                <h4>{item.title}</h4>
                <p className="text">{item.text}</p>
              </div>
              <hr />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const HighlightStyles = css`
  display: flex;
  margin-top: 12rem;

  @media screen and (${theme.breakpoints.mediumDown}) {
    margin-top: 6rem;
  }

  @media screen and (${theme.breakpoints.desktopDown}) {
    display: block;
  }

  .left {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 45%;
    padding: 4rem 6rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    border-radius: 2.5rem 0rem 0rem 2.5rem;
    background: ${theme.colors.gradients.orange};
    color: white;

    @media screen and (${theme.breakpoints.desktopDown}) {
      border-radius: 2.5rem;
      width: auto;
    }

    @media screen and (${theme.breakpoints.mediumDown}) {
      padding: 4rem;
    }

    h1 {
      margin-bottom: 2rem;
    }

    h4 {
      margin-bottom: 4rem;

      @media screen and (${theme.breakpoints.mediumDown}) {
        margin-bottom: 2rem;
      }
    }

    .bullet {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 1rem;

      @media screen and (${theme.breakpoints.mediumDown}) {
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0rem;
        font-weight: 500;
        line-height: 4rem;
        max-width: 40rem;
      }

      svg {
        margin-right: 3rem;
        margin-top: 1rem;
        font-size: 2rem;

        @media screen and (${theme.breakpoints.mobileDown}) {
          margin-right: 2rem;
        }
      }
    }

    p.text {
      margin-top: 4rem;
    }
  }

  .right {
    height: fit-content;
    width: 55%;
    border-radius: 2.5rem;
    background-color: #ffffff;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.14);

    @media screen and (${theme.breakpoints.desktopDown}) {
      width: 100%;
    }

    .item {
      position: relative;
      display: grid;
      grid-template-columns: 0.75fr 4fr;
      min-height: 10.5rem;
      padding: 3.5rem 5.5rem 3.5rem 9rem;

      @media screen and (${theme.breakpoints.desktopDown}) {
        grid-template-columns: 1fr 5fr;
      }

      @media screen and (${theme.breakpoints.smallDown}) {
        grid-template-columns: 1fr 4fr;
        padding: 3.5rem 5rem;
      }

      @media screen and (${theme.breakpoints.mobileDown}) {
        padding: 2.5rem 3rem;
      }

      .block {
        margin-left: 2rem;

        @media screen and (${theme.breakpoints.smallDown}) {
          margin-left: 1.5rem;
        }

        h4 {
          font-weight: 600;
        }

        p {
          margin-top: 0.5rem;
        }
      }

      hr {
        position: absolute;
        bottom: 0rem;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 0.05rem;
        width: 70%;
        background-color: #d8d8d8;
        opacity: 0.2;
        margin: 0rem;
      }

      &:last-of-type {
        hr {
          display: none;
        }
      }

      .icon-wrapper {
        display: flex;
        align-items: center;

        @media screen and (${theme.breakpoints.mobileDown}) {
          align-items: flex-start;
          padding-top: 1rem;
        }

        .icon {
          color: ${theme.colors.fontcolors.orange};
          font-size: 4.5rem;

          &.island-tropical {
            margin-left: 12%;
          }

          &.rocket {
            margin-left: 9%;
          }

          @media screen and (${theme.breakpoints.mobileDown}) {
            font-size: 3.5rem;
          }
        }
      }
    }
  }

  .text {
    font-size: 1.5rem;
  }
`
