/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import * as React from "react"
import {
  TestimonialBlock as ITestimonialBlock,
  Graduate,
} from "../types/content-blocks"
import {theme} from "../components/styles/Theme"

export const Testimonial = (content: ITestimonialBlock) => {
  return (
    <div css={TestimonialStyles}>
      <h1>{content.title}</h1>
      {content.items.map((graduate: Graduate, index) => {
        const styleIndex = index % 2 == 0 ? "even" : "odd"
        return (
          <div
            key={index}
            css={GraduateStyles(
              graduate.color.hex,
              graduate.subtleColor.hex,
              graduate.fontColor.hex,
              styleIndex,
            )}
          >
            <div className="image-background">
              <img src={`${graduate.photo.url}`} alt="" />
            </div>
            <div className="testimonial">
              <p className="large quote">{graduate.quote}</p>
              <p className="medium name">{`- ${graduate.name}, Class of '${graduate.year}`}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const TestimonialStyles = css`
  margin-top: 12rem;

  @media screen and (${theme.breakpoints.desktopDown}) {
    margin-top: 6rem;
  }

  h1 {
    text-align: center;
    margin-top: 0rem;
    margin-bottom: 8rem;
    font-weight: 600;
  }
`

const GraduateStyles = (
  color: string,
  backgroundColor: string,
  fontColor: string,
  styleIndex: string,
) => css`
  display: flex;
  flex-direction: ${styleIndex == "even" ? "row" : "row-reverse"};
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 8rem;
  background-color: ${backgroundColor};
  border-radius: 2.5rem;
  width: 80%; /* to remove once we have headshots */

  @media screen and (${theme.breakpoints.mediumDown}) {
    width: 100%; /* to remove once we have headshots */
    margin-bottom: 5rem;
  }

  @media screen and (${theme.breakpoints.smallDown}) {
    display: block;
  }

  .image-background {
    display: none; /* to remove once we have headshots */
    position: relative;
    background-color: ${color};
    min-width: 28rem;
    height: 20rem;
    border-radius: 2.5rem;

    @media screen and (${theme.breakpoints.mediumDown}) {
      min-width: 20rem;
      height: 15rem;
    }

    img {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 0rem;
      height: 23rem;

      @media screen and (${theme.breakpoints.mediumDown}) {
        height: 18rem;
      }
    }
  }

  .testimonial {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 2.5rem;
    color: ${fontColor};
    /* padding: 2rem 8rem; */
    padding: 4rem 8rem; /* to remove once we have headshots */
    text-align: left;

    @media screen and (${theme.breakpoints.mediumDown}) {
      /* padding: 1rem 4rem; */
      padding: 4rem 4rem; /* to remove once we have headshots */
    }

    p {
      font-weight: 400;
      line-height: 3rem;
    }

    p.quote {
      font-style: italic;
    }

    p.name {
      color: black;
    }
  }
`
