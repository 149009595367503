/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import {useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlay, faX} from "@fortawesome/free-solid-svg-icons"
import ReactPlayer from "react-player/lazy"
import {VideoBlock} from "../types/content-blocks"
import {theme} from "../components/styles/Theme"
import Modal from "react-modal"

export const Video = (content: VideoBlock) => {
  const [playing, setPlaying] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const customStyles = {
    overlay: {
      zIndex: "1000",
    },
    content: {
      position: "relative",
      overflow: "visible",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      border: "none",
      background: "transparent",
    },
  }

  return (
    <div css={PreviewStyles(content.preview.url)}>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => setPlaying(true)}
        onRequestClose={() => setModalIsOpen(true)}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={true}
      >
        <div css={PlayerStyles}>
          <FontAwesomeIcon
            icon={faX}
            className="x"
            onClick={() => {
              setModalIsOpen(false)
              console.log("click")
              setPlaying(false)
            }}
          />
          <ReactPlayer
            url={content.video.url}
            width="100%"
            height="100%"
            playing={playing}
            controls
            style={{borderRadius: "2.5rem"}}
          />
        </div>
      </Modal>
      <div
        className="play-button"
        onClick={() => {
          setModalIsOpen(true)
        }}
      >
        <FontAwesomeIcon icon={faPlay} />
        {content.playButtonText}
      </div>
    </div>
  )
}

const PreviewStyles = (thumbnail: string) => css`
  position: relative;
  margin: 0 auto;
  max-width: 107rem;
  height: 48rem;
  border-radius: 2.5rem;
  background-image: url(${thumbnail});
  background-size: cover;
  background-position: center;
  cursor: pointer;

  @media screen and (${theme.breakpoints.smallDown}) {
    height: 40rem;
  }

  > .play-button {
    position: absolute;
    bottom: -3.5rem;
    right: 3.6rem;
    padding: 2.3rem 3.2rem;
    background: #ffffff;
    border-radius: 5rem;
    box-shadow: 0 0.2rem 5rem 0 rgba(0, 0, 0, 0.14);
    font-size: 1.6rem;

    @media screen and (${theme.breakpoints.smallDown}) {
      left: 0rem;
      right: 0rem;
      font-size: 1.2rem;
      text-align: center;
    }

    svg {
      margin-right: 2rem;

      @media screen and (${theme.breakpoints.smallDown}) {
        margin-right: 1rem;
      }
    }
  }
`

const PlayerStyles = css`
  margin: 0 auto;
  max-width: 107rem;
  height: 48rem;
  text-align: right;

  .x {
    position: fixed;
    top: 0rem;
    right: 2rem;

    font-size: 1.8rem;
  }
`
