/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import * as React from "react"
import {theme} from "../components/styles/Theme"
import {
  TechnologyBlock as ITechnologyBlock,
  Item,
} from "../types/content-blocks"

export const TechnologyBlock = (content: ITechnologyBlock) => {
  const getItemBlocks = (items: Item[]) => {
    return items.map((item: Item) => {
      return (
        <div className="item" key={`${item.id}`}>
          <div>
            <img src={`${item.logo.url}`} alt={`${item.name}`} />
            <p className="medium">{item.name}</p>
          </div>
        </div>
      )
    })
  }

  const technologyBlocks = () => {
    return Array.from(Array(content.items.length / 5).keys()).map(index => {
      const offset = index * 5

      return [
        content.items.slice(offset, offset + 2),
        content.items.slice(offset + 2, offset + 5),
      ].map(items => {
        return (
          <div className="item-chunk" key={items[0].id}>
            {getItemBlocks(items)}
          </div>
        )
      })
    })
  }

  return (
    <div css={TechnologiesStyles}>
      <div className="technologies">{technologyBlocks()}</div>
      <div className="learning">
        <h1>{content.title}</h1>
        <div dangerouslySetInnerHTML={{__html: content.content}} />
      </div>
    </div>
  )
}

const TechnologiesStyles = css`
  display: flex;
  margin-top: 15rem;

  @media screen and (${theme.breakpoints.largeDesktopDown}) {
    margin-top: 0rem;
  }

  @media screen and (${theme.breakpoints.largeDesktopDown}) {
    flex-wrap: wrap-reverse;
  }

  .technologies {
    display: flex;
    align-items: center;

    @media screen and (${theme.breakpoints.largeDesktopDown}) {
      justify-content: center;
      margin: auto;
    }

    @media screen and (${theme.breakpoints.smallDown}) {
      display: block;
      margin: auto;
    }

    .item-chunk {
      margin-right: 1.3rem;

      @media screen and (${theme.breakpoints.smallDown}) {
        display: flex;
        justify-content: center;
        margin-right: 0rem;
      }

      .item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 17.5rem;
        width: 11.5rem;
        padding: 1.2rem;
        margin-bottom: 2rem;
        margin-right: 1.3rem;
        border-radius: 2.5rem;
        background-color: white;
        box-shadow: 0 0 80px 0 rgba(33, 33, 33, 0.1);

        @media screen and (${theme.breakpoints.largeDesktopDown}) {
          height: 11.5rem;
        }

        @media screen and (${theme.breakpoints.desktopDown}) {
          width: fit-content;
          padding: 0.5rem 1.5rem;
        }

        @media screen and (${theme.breakpoints.mediumDown}) {
          height: 9.5rem;
        }

        @media screen and (${theme.breakpoints.smallDown}) {
          height: 6.5rem;
        }

        p {
          margin: 0rem;
          @media screen and (${theme.breakpoints.largeDesktopDown}) {
            display: none;
          }
        }

        img {
          margin-bottom: 2rem;
          max-width: 8rem;

          @media screen and (${theme.breakpoints.largeDesktopDown}) {
            margin-bottom: 0rem;
          }

          @media screen and (${theme.breakpoints.mediumDown}) {
            max-width: 6rem;
          }

          @media screen and (${theme.breakpoints.smallDown}) {
            max-width: 5rem;
          }
        }
      }
    }
  }

  .learning {
    padding: 9rem 0rem 9rem 7rem;

    @media screen and (${theme.breakpoints.largeDesktopDown}) {
      text-align: center;
      padding-left: 0rem;
    }

    @media screen and (${theme.breakpoints.mediumDown}) {
      padding: 8rem 0rem;
    }

    @media screen and (${theme.breakpoints.mobileDown}) {
      padding: 8rem 0rem 4rem 0rem;
    }

    h1 {
      margin-bottom: 4rem;
      font-weight: 600;
    }

    h4 {
      font-weight: 200;
    }

    p {
      font-size: 2rem;
      @media screen and (${theme.breakpoints.desktopDown}) {
        margin: auto;
        font-size: 1.6rem;
        width: 70%;
      }

      @media screen and (${theme.breakpoints.smallDown}) {
        width: 90%;
      }
    }
  }
`
