/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import * as React from "react"
import {theme} from "../components/styles/Theme"
import {ProcessBlock as IProcessBlock} from "../types/content-blocks"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faUpload} from "@fortawesome/pro-light-svg-icons"
import Step1 from "/src/images/steps-copy-1.svg"
import Step2 from "/src/images/steps-copy-2.svg"
import Step3 from "/src/images/steps-copy-3.svg"
import Step4 from "/src/images/steps-copy-4.svg"
import Step5 from "/src/images/steps-copy-5.svg"
import {Button} from "../components/shared/button"

export const Process = (content: IProcessBlock) => {
  const images = [Step1, Step2, Step3, Step4, Step5]
  return (
    <div css={ProcessWrapperStyles}>
      <div css={ProcessStyles}>
        <h1>{content.title}</h1>
        <div className="step-lines">
          <div className="link one">
            <div className="link-block"></div>
          </div>
          <div className="link two">
            <div>
              <img src={Step5} alt="icon" />
            </div>
          </div>
        </div>
        <div className="steps">
          {content.steps.map((step, index) => {
            return (
              <div key={index} className={`step-wrapper index-${index + 1}`}>
                <img src={images[index]} alt="icon" />
                <div className={`step index-${index + 1}`}>
                  {index == 0 && <FontAwesomeIcon icon={faUpload} />}
                  <h4>{step.title}</h4>
                  <p className="subtitle">{step.subtitle}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="call-to-action">
        <h2>{content.callToAction}</h2>
        <Button
          text={content.vacancyActive ? "Apply now" : "Notify me"}
          width={28}
          height={7.5}
          fontWeight="normal"
          color="black"
          url={content.url}
        />
      </div>
    </div>
  )
}

const ProcessStyles = css`
  margin-top: 12rem;
  position: relative;

  @media screen and (${theme.breakpoints.mediumDown}) {
    margin-top: 6rem;
  }

  h1 {
    text-align: center;
    margin-bottom: 8rem;

    @media screen and (${theme.breakpoints.smallDown}) {
      margin-bottom: 4rem;
    }
  }

  .step-lines {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    @media screen and (${theme.breakpoints.desktopDown}) {
      position: relative;
    }

    @media screen and (${theme.breakpoints.smallDown}) {
      display: none;
    }

    .link {
      position: absolute;
      height: 23rem;
      border-radius: 0rem 0rem 2.5rem 2.5rem;
      border-bottom: dashed 3px ${theme.colors.fontcolors.orange};
      border-left: dashed 3px ${theme.colors.fontcolors.orange};
      border-right: dashed 3px ${theme.colors.fontcolors.orange};
      @media screen and (${theme.breakpoints.desktopDown}) {
        position: absolute;
        height: 20rem;
      }

      &.one {
        width: 35%;
        left: 11%;
        top: 28rem;

        @media screen and (${theme.breakpoints.desktopDown}) {
          top: 10rem;
          width: 38%;
        }

        .link-block {
          display: none;
          @media screen and (${theme.breakpoints.desktopDown}) {
            position: absolute;
            display: block;
            top: 0rem;
            right: -2rem;
            height: 12rem;
            width: 5rem;
            background-color: white;
          }
        }
      }

      &.two {
        right: 8%;
        top: 28rem;
        margin-left: 8rem;
        width: 27%;
        @media screen and (${theme.breakpoints.desktopDown}) {
          top: 22rem;
          width: 30%;
        }

        > div {
          position: relative;
        }

        img {
          position: absolute;
          top: -18rem;
          right: -11.5rem;

          @media screen and (${theme.breakpoints.desktopDown}) {
            top: -12rem;
            right: -7.8rem;
            height: 14rem;
          }

          @media screen and (${theme.breakpoints.smallDown}) {
            display: none;
          }
        }
      }
    }
  }

  .steps {
    z-index: 50;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.75fr 0.75fr;
    width: 100%;

    @media screen and (${theme.breakpoints.smallDown}) {
      display: block;
      width: auto;
      padding: 2rem;
      box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.14);
      border-radius: 2.5rem;
    }

    .step-wrapper {
      position: relative;
      height: 40rem;

      @media screen and (${theme.breakpoints.desktopDown}) {
        height: 45rem;
      }

      @media screen and (${theme.breakpoints.smallDown}) {
        height: auto;
      }

      img {
        z-index: 100;
        position: absolute;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);

        @media screen and (${theme.breakpoints.desktopDown}) {
          height: 4rem;
        }

        @media screen and (${theme.breakpoints.smallDown}) {
          position: initial;
          margin-left: 50%;
        }
      }

      &.index-1 {
        img {
          height: 10rem;
          top: -8rem;
          left: 50%;

          @media screen and (${theme.breakpoints.desktopDown}) {
            top: -4rem;
            left: 8rem;
            height: 6rem;

            @media screen and (${theme.breakpoints.smallDown}) {
              height: 4rem;
            }
          }
        }
      }

      &.index-2 {
        img {
          left: 11rem;
          bottom: -5rem;

          @media screen and (${theme.breakpoints.desktopDown}) {
            left: 5rem;
            bottom: 8rem;
          }
        }
      }

      &.index-3 {
        img {
          left: 50%;
          top: 20rem;

          @media screen and (${theme.breakpoints.desktopDown}) {
            top: 22.5rem;
          }
        }
      }

      &.index-4 {
        img {
          left: 50%;
          bottom: -5rem;

          @media screen and (${theme.breakpoints.desktopDown}) {
            bottom: -3.5rem;
          }
        }
      }

      &.index-5 {
        img {
          display: none;
        }
      }

      .step {
        z-index: 80;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        height: fit-content;
        border-radius: 2.5rem;
        background-color: #ffffff;
        box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.14);
        text-align: center;

        p {
          margin: 0rem;
          font-size: 1.5rem;
          text-transform: uppercase;
        }

        svg {
          color: ${theme.colors.fontcolors.orange};
          margin-bottom: 2.7rem;
          font-size: 4.8rem;

          @media screen and (${theme.breakpoints.desktopDown}) {
            margin-bottom: 1.5rem;
            font-size: 3.5rem;
          }

          @media screen and (${theme.breakpoints.smallDown}) {
            display: none;
          }
        }

        &.index-1 {
          top: 0;
          width: 20rem;
          padding: 4.5rem 3.5rem;

          @media screen and (${theme.breakpoints.desktopDown}) {
            left: 8rem;
            padding: 2.5rem 1.5rem;
          }

          @media screen and (${theme.breakpoints.smallDown}) {
            width: auto;
            padding: 0rem;
          }
        }

        &.index-2 {
          left: 11rem;
          bottom: 0;
          width: 23rem;
          padding: 2rem 4rem;

          @media screen and (${theme.breakpoints.desktopDown}) {
            top: 58%;
            left: 5rem;
            width: 21rem;
            padding: 1.5rem 2rem;

            @media screen and (${theme.breakpoints.smallDown}) {
              width: auto;
              padding: 0rem;
            }
          }
        }

        &.index-3 {
          top: 31%;
          width: 23rem;
          padding: 2rem 4rem;
          @media screen and (${theme.breakpoints.desktopDown}) {
            top: 37%;
            width: 21rem;
            padding: 1rem;
          }

          @media screen and (${theme.breakpoints.smallDown}) {
            width: auto;
            padding: 0rem;
          }
        }

        &.index-4 {
          bottom: 0;
          width: 12rem;
          width: 23rem;
          padding: 1.5rem 2rem;

          @media screen and (${theme.breakpoints.desktopDown}) {
            top: 84%;
            width: 15rem;
            padding: 1.5rem 2.5rem;
          }

          @media screen and (${theme.breakpoints.smallDown}) {
            width: auto;
            padding: 0rem;
          }
        }

        &.index-5 {
          top: 46%;
          width: 9rem;
          padding: 3.3rem 4.5rem;

          h4 {
            font-weight: 500;
          }

          @media screen and (${theme.breakpoints.desktopDown}) {
            top: 61%;
            padding: 2.5rem 3rem;
          }

          @media screen and (${theme.breakpoints.smallDown}) {
            width: auto;
            padding: 0rem;
          }

          h4 {
            @media screen and (${theme.breakpoints.smallDown}) {
              font-weight: 600;
            }
          }
        }

        @media screen and (${theme.breakpoints.smallDown}) {
          position: initial;
          transform: none;
          box-shadow: none;
          padding: 0rem;
          margin-bottom: 3rem;
        }
      }
    }
  }
`
const ProcessWrapperStyles = css`
  margin-bottom: 12rem;

  .call-to-action {
    display: flex;
    justify-content: space-between;
    margin-top: 12rem;
    border-radius: 2.5rem;
    padding: 5.5rem 6rem;
    background: ${theme.colors.gradients.orange};
    color: white;

    @media screen and (${theme.breakpoints.smallDown}) {
      display: block;
      text-align: center;
      margin-top: 4rem;
    }

    h2 {
      font-weight: 600;

      @media screen and (${theme.breakpoints.smallDown}) {
        margin-bottom: 2rem;
      }
    }
  }
`
