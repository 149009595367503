export const theme = {
  colors: {
    gradients: {
      orange: "linear-gradient(216.55deg, #FD9D00 0%, #FA6400 100%)",
      button: "linear-gradient(135deg, #FD9D00 0%, #FA6400 100%)",
    },
    buttoncolors: {
      black: "#000000",
      orange: "#F48515",
      blue: "#00CBFA",
    },
    fontcolors: {
      orange: "#FA6400",
      title: "#000000",
      body: "#292929",
      brown: "#866A45",
      green: "#65916C",
    },
  },
  breakpoints: {
    mobileDown: "max-width: 480px",
    mobile: "min-width: 480px",
    smallDown: "max-width: 640px",
    small: "min-width: 640px",
    mediumDown: "max-width: 800px",
    medium: "min-width: 800px",
    desktopDown: "max-width: 960px",
    desktop: "min-width: 960px",
    largeDesktopDown: "max-width: 1235px",
    largeDesktop: "min-width: 1235px",
    extraLargeDesktop: "min-width: 1650px",
    extraLargeDesktopDown: "max-width: 1375px",
  },
}
